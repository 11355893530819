import { IntroOnPage } from './intro-on-page';
import { componentSelector } from './selectors';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    document.querySelectorAll(componentSelector).forEach(($intro) => new IntroOnPage($intro));
  },
  false
);

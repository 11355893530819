/**
 * @typedef {Object} IntroOnPageConfiguration
 * @property {Object} introOnPage
 * @property {Number} introOnPage.ttlInSeconds
 * @property {Object} cookie
 * @property {Number} cookie.ttlInDays
 * @property {String} cookie.name
 */

import { closeIntroSelectors, temporaryCloseIntroSelector } from './selectors';

/**
 * @param {HTMLElement} $el
 * @return {IntroOnPageConfiguration}
 */
const getConfig = ($el) => JSON.parse($el.dataset.configuration);

export class IntroOnPage {
  constructor($intro) {
    this.$intro = $intro;
    this.config = getConfig(this.$intro);

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('Intro on page', {
        $intro: this.$intro,
        config: this.config,
      });
    }

    this.init();
  }

  /**
   * @private
   */
  init() {
    const cookieIsStillborn = this.config.cookie.ttlInDays === 0;

    if (cookieIsStillborn) {
      this.removeCookie();
    }

    if (this.readCookie() === null) {
      this.bindEvents();
      this.setupAutoRemoval();
    } else {
      this.remove();
    }
  }

  /**
   * @private
   */
  bindEvents() {
    const $btnTemporaryClose = this.$intro.querySelector(temporaryCloseIntroSelector);

    this.$intro.querySelectorAll(closeIntroSelectors).forEach(($el) => {
      $el.addEventListener(
        'click',
        (e) => {
          e.preventDefault();
          this.createCookie();
          this.remove();
        },
        false
      );
    });

    if ($btnTemporaryClose) {
      $btnTemporaryClose.addEventListener(
        'click',
        (e) => {
          e.preventDefault();
          this.remove();
        },
        false
      );
    }
  }

  setupAutoRemoval() {
    const ttl = this.config.introOnPage.ttlInSeconds;

    if (ttl === 0) {
      this.remove();
    } else {
      setInterval(this.remove.bind(this), ttl * 1000);
    }
  }

  /**
   * @private
   */
  remove() {
    this.$intro.remove();
  }

  /**
   * @private
   */
  readCookie() {
    return global.readCookie(this.config.cookie.name);
  }

  /**
   * @private
   */
  createCookie() {
    const { name, ttlInDays } = this.config.cookie;

    return global.createCookie(name, 1, ttlInDays);
  }

  /**
   * @private
   */
  removeCookie() {
    return global.eraseCookie(this.config.cookie.name);
  }
}
